import { useEffect, useState } from "react";

export default function Choice(props) {
  const { onClick, colour, selected, design } = props;

  const [currentColour, setCurrentColour] = useState(
    "bg-gradient-to-t from-slate-300 to-slate-50"
  );

  useEffect(() => {
    switch (colour) {
      default:
        setCurrentColour("bg-black");
        break;
      case "red":
        setCurrentColour("bg-gradient-to-t from-rose-900 to-red-700");
        break;
      case "orange":
        setCurrentColour("bg-gradient-to-t from-orange-800 to-orange-600");
        break;
      case "yellow":
        setCurrentColour("bg-gradient-to-t from-yellow-700 to-yellow-500");
        break;
      case "green":
        setCurrentColour("bg-gradient-to-t from-lime-700 to-lime-500");
        break;
      case "blue":
        setCurrentColour("bg-gradient-to-t from-sky-700 to-sky-500");
        break;
      case "indigo":
        setCurrentColour("bg-gradient-to-t from-indigo-700 to-indigo-500");
        break;
      case "violet":
        setCurrentColour("bg-gradient-to-t from-fuchsia-900 to-fuchsia-700");
        break;
      case "white":
        setCurrentColour("bg-gradient-to-t from-slate-300 to-slate-50");
        break;
      case "lightGray":
        setCurrentColour("bg-gradient-to-t from-gray-500 to-gray-300");
        break;
      case "darkGray":
        setCurrentColour("bg-gradient-to-t from-gray-700 to-gray-500");
        break;
      case "black":
        setCurrentColour("bg-gradient-to-t from-slate-900 to-slate-700");
        break;
      case "lightBrown":
        setCurrentColour("bg-gradient-to-t from-amber-800 to-amber-700");
        break;
      case "brown":
        setCurrentColour("bg-gradient-to-t from-amber-900 to-amber-800");
        break;
      case "darkBrown":
        setCurrentColour("bg-gradient-to-t from-amber-950 to-amber-900");
        break;
    }
  }, [colour]);

  return (
    <div className="w-12 h-12 flex items-center justify-center shrink-0  ">
      <button
        className={`${currentColour}  border-4 ${
          selected ? "w-12 h-12" : "w-10 h-10"
        } shadow shadow-black/60 border-black  rounded-full relative transition-all shrink-0 overflow-hidden`}
        onClick={onClick}
      >
        {[1, 2, 3, 4, 5, 6].map((num, index) => {
          return (
            <img
            key={index}
              alt=""
              className={
                `${
                design === num ? "absolute" : "hidden"
              } h-full w-full left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 brightness-0 invert`}
              src={require("../images/shield-designs/shield-design-" +
                num +
                ".png")}
            ></img>
          );
        })}
      </button>
    </div>
  );
}
