export default function Textplate(props) {
  const { children } = props;
  return (
    <div className="relative shadow-md text-2xl p-1.5 w-fith-auto bg-cover bg-center shadow-black/50 bg-wood border-4 border-black/50 transition-all select-none  text-center select-none text-center flex items-center justify-center">
      <div className="overflow-hidden relative text-orange-950 border-2 border-black/30 bg-gradient-to-tr from-orange-700/90 via-orange-900/90 to-orange-700/90  leading-relaxed shadow shadow-black p-1  w-fit h-full   text-center flex items-center justify-center">
        
        <div
            className={`-top-3 -left-2  w-[7%] h-[200%] bg-white/15 absolute rotate-[12deg] transition-all duration-500`}
          ></div>
          <div
            className={`-top-3 -right-3  w-[10%] h-[200%] bg-black/10 absolute rotate-[12deg] transition-all duration-500`}
          ></div>
          {children}
      </div>
    </div>
  );
}
