import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "./Button";
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";

export default function Selector(props) {
  const { clickFunction, direction } = props;
  return <div className={`w-[100px] aspect-square bg-gradient-to-t from-neutral-950 to-neutral-900 rounded-2xl text-neutral-400 text-[50px] flex justify-center items-center`}
  onClick={clickFunction}>
    <FontAwesomeIcon icon={direction === "left" ? faCaretLeft : faCaretRight}></FontAwesomeIcon>
  </div>
}
