import { useCallback, useState } from "react";
import Background from "../components/Background";
import Choice from "../components/Choice";
import Longship from "../components/Longship";
import Waves from "../components/Waves";
import Player from "../components/Player";

export default function Landscape(props) {
  const { orientation, username, users, host, customization, setCustomization, debug } = props;

  const [primaryColour, setPrimaryColour] = useState(
    customization["primaryColour"]
  );
  const [secondaryColour, setSecondaryColour] = useState(
    customization["secondaryColour"]
  );

  const changePrimaryColour = useCallback((newPrimary) => {
    setPrimaryColour(newPrimary);
    localStorage.setItem("primary", newPrimary);
    setCustomization({primaryColour: newPrimary, secondaryColour});
  }, [secondaryColour, setCustomization]);

  const changeSecondaryColour = useCallback((newSecondary) => {
    setSecondaryColour(newSecondary);
    localStorage.setItem("secondary", newSecondary);
    setCustomization({primaryColour, secondaryColour: newSecondary});
  }, [primaryColour, setCustomization]);

  return (
    <div
      className={`${
        debug ? "hidden" : "landscape:flex portrait:hidden"
      }  w-screen h-screen  flex-col items-center justify-center overflow-hidden relative select-none bg-transition bg-cover`}
    >

      <Player username={username} customization={{primaryColour, secondaryColour}}></Player>

      <div className={`flex`}>
        {["red", "orange", "yellow", "green", "blue", "indigo", "violet"].map(
          (colour, index) => (
            <Choice
              onClick={() => changePrimaryColour(colour)}
              selected={
                primaryColour === colour || (!primaryColour && index === 0)
              }
              colour={colour}
              key={index}
            ></Choice>
          )
        )}
      </div>
      <div className={`flex`}>
        {["red", "orange", "yellow", "green", "blue", "indigo", "violet"].map(
          (colour, index) => (
            <Choice
              onClick={() => changeSecondaryColour(colour)}
              selected={
                secondaryColour === colour || (!secondaryColour && index === 0)
              }
              colour={colour}
              key={index}
            ></Choice>
          )
        )}
      </div>
      {/* <Container
          justifyContent={ "center"}
          gap={"5px"}
        >
          <Container
            horizontal={true}
            justifyContent="center"
            background={true}
          >
            {[
              "red",
              "orange",
              "yellow",
              "green",
              "blue",
              "indigo",
              "violet",
            ].map((colour, index) => (
              <Choice
                onClick={() => changePrimaryColour(colour)}
                selected={
                  primaryColour === colour || (!primaryColour && index === 0)
                }
                colour={colour}
                key={index}
              ></Choice>
            ))}
          </Container>
          <Container
            horizontal={true}
            justifyContent="center"
            background={true}
          >
            {[
              "white",
              "lightGray",
              "darkGray",
              "black",
              "lightBrown",
              "brown",
              "darkBrown",
            ].map((colour, index) => (
              <Choice
                onClick={() => changeSecondaryColour(colour)}
                selected={
                  secondaryColour === colour || (!secondaryColour && index === 0)
                }
                colour={colour}
                key={index}
              ></Choice>
            ))}
          </Container>
          <Container
            horizontal={true}
            justifyContent="center"
            background={true}
          >
            {[0, 1, 2, 3, 4, 5, 6].map((d, index) => (
              <Choice
                onClick={() => changeDesign(d)}
                selected={design === d || (!design && index === 0)}
                design={d}
                key={index}
              ></Choice>
            ))}
          </Container>
        </Container> */}
    </div>
  );
}

{
  /* <Background orientation={orientation}></Background>
      <Waves orientation={orientation} position={"back"}></Waves>
      <Longship
        orientation={orientation}
        users={users}
        host={host}
        shield={shield}
      ></Longship>
      <Waves orientation={orientation} position={"middle"}></Waves>
      <Waves orientation={orientation} position={"front"}></Waves> */
}
