import { useCallback, useEffect, useRef, useState } from "react";
import Button from "../components/Button";
import { socket } from "../socket";
import Textplate from "../components/Textplate";
import Container from "../components/Container";
import Profile from "../components/Profile";
import InputSelector from "../components/InputSelector";
import Role from "../components/Role";
import Player from "../components/Player";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import ActionButton from "../components/ActionButton";
import Selector from "../components/Selector";

export default function Lobby(props) {
  const { username, room, users, host, orientation, roles } = props;

  const [selectedPlayer, setSelectedPlayer] = useState(null);

  const [nightTimeLimit, setNightTimeLimit] = useState(
    localStorage.getItem("nightTimeLimit") === null
      ? 120
      : parseInt(localStorage.getItem("nightTimeLimit"))
  );
  const [dayTimeLimit, setDayTimeLimit] = useState(
    localStorage.getItem("dayTimeLimit") === null
      ? 120
      : parseInt(localStorage.getItem("dayTimeLimit"))
  );
  const [transitionTimeLimit, setTransitionTimeLimit] = useState(
    localStorage.getItem("transitionTimeLimit") === null
      ? 3
      : parseInt(localStorage.getItem("transitionTimeLimit"))
  );
  const [viewedRole, setViewedRole] = useState(0);

  const [page, setPage] = useState("main");

  const playerContainerRef = useRef(null);

  const leaveRoom = useCallback(() => {
    socket.emit("leave");
  }, []);

  const startGame = useCallback(() => {
    console.log("Starting game.");
    console.log(typeof nightTimeLimit);
    console.log(typeof dayTimeLimit);
    console.log(typeof transitionTimeLimit);
    socket.emit("start", {
      room,
      nightTimeLimit,
      dayTimeLimit,
      transitionTimeLimit,
    });
  }, [room, nightTimeLimit, dayTimeLimit, transitionTimeLimit]);

  const changeSetting = useCallback((setting, value) => {
    console.log(setting, value);
    if (setting === "nightTimeLimit") {
      setNightTimeLimit(value);
    } else if (setting === "dayTimeLimit") {
      setDayTimeLimit(value);
    } else if (setting === "transitionTimeLimit") {
      setTransitionTimeLimit(value);
    }
    localStorage.setItem(setting, value);
  }, []);

  const clickPlayer = useCallback(
    (username) => {
      selectedPlayer ? setSelectedPlayer(null) : setSelectedPlayer(username);
    },
    [selectedPlayer]
  );

  const changeHost = useCallback(
    (newHost) => {
      socket.emit("change_host", { room, newHost });
      clickPlayer();
    },
    [room, clickPlayer]
  );


  useEffect(() => {
    console.log(users)
  }, [users]);

  return (
    <div
      className={`flex flex-col items-center justify-end gap-10 w-full h-full bg-transition bg-cover bg-center `}
    >
      {/* PORTRAIT */}

      <div
        className={`portrait:flex landscape:hidden w-full h-full justify-start flex-col items-center`}
      >
        <div
          className={`w-full h-[15%] border-b-4 border-black/10 bg-black/20 backdrop-blur flex  items-center justify-around portrait:flex landscape:hidden`}
        >
          <Textplate>ROOM {room}</Textplate>

          {host === username ? (
            <div
              className={`w-[50px] aspect-square border-4 border-neutral-300 text-neutral-300 text-3xl flex justify-center items-center  rounded-full`}
              onClick={() => setPage(page === "main" ? "settings" : "main")}
            >
              <FontAwesomeIcon icon={faCog}></FontAwesomeIcon>
            </div>
          ) : null}
        </div>

        <div className={`flex w-full h-[60%] relative `}>
          {/* SELECTED PLAYER */}

          <div
            className={`${
              selectedPlayer
                ? "w-full h-full  flex flex-col justify-center items-center"
                : "hidden"
            }    absolute z-10`}
          >
            <div
              className={`w-full h-full bg-black absolute backdrop-blur bg-black/40 `}
              onClick={() => clickPlayer()}
              // onClick={() => clickPlayer()}
            ></div>

            <Player
              username={selectedPlayer}
              stamps={[
                {
                  name: "host",
                  active: host === selectedPlayer,
                },
              ]}
              selectFunction={() => clickPlayer(selectedPlayer)}
              highlight={selectedPlayer === username ? "yellow" : null}
              state={"lobby"}
              customization={selectedPlayer ? users.find((user) => user["username"] === selectedPlayer)["customization"] : null}
            ></Player>

            <div
              className={`w-full h-2/3  flex flex-col justify-start items-center pt-10 gap-1 overflow-y-scroll `}
            >
              <ActionButton
                text={"Host"}
                clickFunction={() => changeHost(selectedPlayer)}
                active={selectedPlayer !== username}
                visible={host === username}
              ></ActionButton>
            </div>
          </div>

          <div
            className={` flex-row  overflow-y-scroll  w-full h-full justify-around items-center gap-y-5  py-5 flex-wrap ${
              page === "main" ? "flex" : "hidden"
            }`}
            ref={playerContainerRef}
          >
            {users.map((user, index) => {
              return (
                <Player
                  key={index}
                  highlight={user["username"] === username ? "yellow" : null}
                  username={user["username"]}
                  selectFunction={() => clickPlayer(user["username"])}
                  // state={"alive"}
                  // selected={"attack"}
                  state={"lobby"}
                  stamps={[{ name: "host", active: host === user["username"] }]}
                  customization={user["customization"]}
                  // setHostFunction={host === username && user["username"] !== username ? () => changeHost(user["username"]): null}
                  // options={[host === username && user["username"] !== username ? {name:"Set Host", func: () => changeHost(user["username"]), active: true, action:"host"} : null]}
                ></Player>
              );
            })}
          </div>

          <div
            className={`${
              page === "settings" ? "flex" : "hidden"
            } w-full h-full justify-center items-center flex-col overflow-y-scroll gap-1`}
          >
            <Textplate>Night Time Limit</Textplate>
            <InputSelector
              elements={[
                120, 135, 150, 165, 180, 3, 5, 10, 15, 30, 45, 60, 75, 90, 105,
              ]}
              setValue={changeSetting}
              setValueParam={"nightTimeLimit"}
              defaultElement={nightTimeLimit}
            ></InputSelector>
            <Textplate>Day Time Limit</Textplate>
            <InputSelector
              elements={[
                120, 135, 150, 165, 180, 3, 5, 10, 15, 30, 45, 60, 75, 90, 105,
              ]}
              setValue={changeSetting}
              setValueParam={"dayTimeLimit"}
              defaultElement={dayTimeLimit}
            ></InputSelector>
            {/* <Textplate>Transition Time Limit</Textplate>
            <InputSelector
              elements={[
                3, 5, 10, 15, 30, 45, 60, 75, 90, 105, 120, 135, 150, 165, 180,
              ]}
              setValue={changeSetting}
              setValueParam={"transitionTimeLimit"}
              defaultElement={transitionTimeLimit}
            ></InputSelector> */}
          </div>
        </div>

        <div
          className={` w-full h-[25%] bg-black/20  backdrop-blur border-t-4 border-black/10 flex flex-col items-center justify-center`}
        >
          <div
            className={`flex w-full h-1/2 items-center justify-center gap-1`}
          >
            {host === username && users.length >= 4 ? (
              <Button clickFunction={startGame}>Start</Button>
            ) : null}
            <Button clickFunction={leaveRoom}>Leave</Button>
          </div>
        </div>
      </div>

      {/* LANDSCAPE */}

      <div
        className={`portrait:hidden landscape:flex w-full h-full justify-center items-center gap-1`}
      >
        {/* <div
          className={`h-[100px] aspect-square bg-black/10 rounded-3xl text-center flex justify-center items-center text-3xl border-4 border-black`}
          onClick={() =>
            setViewedRole(
              viewedRole === 0 ? Object.keys(roles).length - 1 : viewedRole - 1
            )
          }
        >
          ◄
        </div> */}
        <Selector
          direction={"left"}
          clickFunction={() =>
            setViewedRole(
              viewedRole === 0 ? Object.keys(roles).length - 1 : viewedRole - 1
            )
          }
        ></Selector>
        {roles &&
          Object.values(roles).map((role, index) => {
            return (
              <Role
                role={role}
                visible={viewedRole === index}
                key={index}
              ></Role>
            );
          })}

        <Selector
          direction={"right"}
          clickFunction={() =>
            setViewedRole(
              viewedRole === Object.keys(roles).length - 1 ? 0 : viewedRole + 1
            )
          }
        ></Selector>
        {/* <div
          className={`h-[100px] aspect-square bg-black/10 rounded-3xl text-center flex justify-center items-center text-3xl border-4 border-black`}
          onClick={() =>
            setViewedRole(
              viewedRole === Object.keys(roles).length - 1 ? 0 : viewedRole + 1
            )
          }
        >
          ►
        </div> */}
      </div>
    </div>
  );
}
