import { useCallback, useEffect, useState } from "react";
import { socket } from "../socket";
import Button from "../components/Button";
import Textplate from "../components/Textplate";
import Player from "../components/Player";
import Role from "../components/Role";
import {
  faCaretLeft,
  faCaretRight,
  faEye,
  faEyeSlash,
  faLock,
  faLockOpen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ActionButton from "../components/ActionButton";
import Selector from "../components/Selector";

export default function Game(props) {
  const {
    debugMode,
    username,
    room,
    users,
    host,
    role,
    visibleTeammates = [],
    timeLimits,
    roles,
    entrancePhase = "start",
    entrancePhaseCount = 1,
    entranceDead = [],
    entranceExiles = [],
    entranceCooldown = 0,
  } = props;

  const [time, setTime] = useState(timeLimits[2]);
  const [targetedPlayers, setTargetedPlayers] = useState([null]);
  const [votedPlayers, setVotedPlayers] = useState([]);
  // const [assassinatedPlayers, setAssassinatedPlayers] = useState([null]);
  const [roleGuess, setRoleGuess] = useState(null);

  const [usernames, setUsernames] = useState(
    users.map((user) => {
      return user["username"];
    })
  );
  const [latestDead, setLatestDead] = useState([]);
  const [currentDead, setCurrentDead] = useState(entranceDead);

  const [latestExiles, setLatestExiles] = useState([]);
  const [currentExiles, setCurrentExiles] = useState(entranceExiles);
  const [returnedExiles, setReturnedExiles] = useState([]);

  const [latestInjured, setLatestInjured] = useState([]);
  const [currentInjured, setCurrentInjured] = useState([]);

  const [latestInfo, setLatestInfo] = useState([]);

  const [phase, setPhase] = useState(entrancePhase);
  const [moon, setMoon] = useState(0);
  const [cooldown, setCooldown] = useState(entranceCooldown);

  const [winner, setWinner] = useState(null);
  const [viewedRole, setViewedRole] = useState(role["id"]);
  const [viewedFinalRole, setViewedFinalRole] = useState(0);

  const [ghostVoteUsed, setGhostVoteUsed] = useState(false);
  const [finalUsers, setFinalUsers] = useState([]);

  const [hideScreen, setHideScreen] = useState(false);
  const [lockedIn, setLockedIn] = useState(false);
  const [readiedUp, setReadiedUp] = useState(false);

  const [history, setHistory] = useState([]);
  const [phaseCount, setPhaseCount] = useState(entrancePhaseCount);
  const [viewedPhase, setViewedPhase] = useState(entrancePhaseCount);

  const [lockedInUsernames, setLockedInUsernames] = useState([]);

  const [selectedPlayer, setSelectedPlayer] = useState(null);

  const [timer, setTimer] = useState();

  const [actionButtonPage, setActionButtonPage] = useState(0);

  const returnToLobby = useCallback(() => {
    console.log("Returning to lobby.");
    socket.emit("lobby", { room });
  }, [room]);

  const vote = useCallback(() => {
    socket.emit("vote", { room, votedPlayers, username });

    if (
      currentDead.indexOf(username) > -1 &&
      !ghostVoteUsed &&
      votedPlayers.length > 0
    ) {
      setGhostVoteUsed(true);
      console.log(username, " used their ghost vote.");
    }
  }, [room, username, votedPlayers, ghostVoteUsed, currentDead]);

  const action = useCallback(() => {
    console.log("Action");
    socket.emit("action", {
      room,
      targetedPlayers,
      username,
      role,
      // assassinatedPlayers,
      roleGuess,
    });
    if (targetedPlayers.indexOf(null) === -1) {
      setCooldown(role["cooldown"]);
      console.log("tp", targetedPlayers);
      console.log(targetedPlayers.indexOf(null));
    } else if (cooldown > 0) {
      setCooldown(cooldown - 1);
    }
  }, [
    room,
    targetedPlayers,
    username,
    role,
    cooldown,
    // assassinatedPlayers,
    roleGuess,
  ]);

  const clickPlayer = useCallback(
    (username) => {
      console.log(username);
      console.log("vp: ", votedPlayers);
      console.log("sp: ", selectedPlayer);

      console.log("vp indexof : ", votedPlayers.indexOf(selectedPlayer));
      setActionButtonPage(0);
      if (username === null || selectedPlayer) {
        setSelectedPlayer(null);
      } else {
        setSelectedPlayer(username);
      }
      // setActionButtonPage(0);
      // selectedPlayer ? setSelectedPlayer(null) : setSelectedPlayer(username);
    },
    [selectedPlayer, votedPlayers]
  );

  const lockIn = useCallback(() => {
    setLockedIn(!lockedIn);
    socket.emit("lockIn", { room, username, debugMode });
  }, [room, username, lockedIn, debugMode]);

  const readyUp = useCallback(() => {
    setReadiedUp(!readiedUp);
    socket.emit("readyUp", { room, username, debugMode });
  }, [room, username, readiedUp, debugMode]);

  const getInfo = useCallback(
    (dead, exiled, injured, resurrected) => {
      console.log("getInfo");
      let updatedDead = [...currentDead];
      let newDead = [];

      dead.forEach((deadPlayer) => {
        if (updatedDead.indexOf(deadPlayer) === -1) {
          updatedDead.push(deadPlayer);
          newDead.push(deadPlayer);
        }
      });
      // console.log("New Dead: ", newDead);

      let updatedExiles = [...currentExiles];
      let returnedExiles = [];

      updatedExiles.forEach((exiledPlayer) => {
        if (exiled.indexOf(exiledPlayer) === -1) {
          returnedExiles.push(exiledPlayer);
          let index = updatedExiles.indexOf(exiledPlayer);
          updatedExiles.splice(index, 1);
        }
      });

      // console.log("Returned Exiles: ", returnedExiles);

      let newExiles = [];

      exiled.forEach((exiledPlayer) => {
        if (updatedExiles.indexOf(exiledPlayer) === -1) {
          updatedExiles.push(exiledPlayer);
          newExiles.push(exiledPlayer);
        }
      });

      // console.log("New Exiles: ", newExiles);

      let updatedInjured = [...currentInjured];
      let newInjured = [];
      let healed = [];

      injured.forEach((injuredPlayer) => {
        if (updatedInjured.indexOf(injuredPlayer) === -1) {
          updatedInjured.push(injuredPlayer);
          newInjured.push(injuredPlayer);
        }
      });

      console.log("injured", injured);
      console.log("updatedInjured", updatedInjured);
      updatedInjured.forEach((injuredPlayer) => {
        if (injured.indexOf(injuredPlayer) === -1) {
          console.log(
            injuredPlayer,
            " not in ",
            injured,
            " therefore they've been healed"
          );
          healed.push(injuredPlayer);
          let index = updatedInjured.indexOf(injuredPlayer);
          updatedInjured.splice(index, 1);
        }
      });
      console.log("updatedInjured", updatedInjured);

      resurrected.forEach((resurrectedPlayer) => {
        let index = updatedDead.indexOf(resurrectedPlayer);
        updatedDead.splice(index, 1);
        updatedInjured.push(resurrectedPlayer);
      }, []);

      // console.log("New Dead: ", newDead);
      setCurrentDead(updatedDead);
      setLatestDead(newDead);
      setCurrentInjured(updatedInjured);
      setLatestInjured(newInjured);
      setCurrentExiles(updatedExiles);
      setLatestExiles(newExiles);
      setReturnedExiles(returnedExiles);
    },
    [currentDead, currentExiles, currentInjured]
  );

  const getLockedInUsernames = useCallback((lockedInUsernames) => {
    setLockedInUsernames(lockedInUsernames);
  }, []);

  const learn = useCallback((info) => {
    console.log("learned", info);
    setLatestInfo(info);
  }, []);

  const getExiles = useCallback(
    (exiles) => {
      console.log("getExiles");
      // console.log(exiles)
      // let newExiles = exiles.map((user) => {
      //   return user["username"];
      // });
      let newExiles = [];
      let updatedExiles = [...currentExiles];
      let returnedExiles = [];
      exiles.forEach((exiledPlayer) => {
        if (updatedExiles.indexOf(exiledPlayer) > -1) {
          let index = updatedExiles.indexOf(exiledPlayer);
          // console.log("index", index);
          // console.log("updatedExiles", updatedExiles);
          updatedExiles.splice(index, 1);
          // console.log("updatedExiles", updatedExiles);
          returnedExiles.push(exiledPlayer);
        } else {
          updatedExiles.push(exiledPlayer);
          newExiles.push(exiledPlayer);
        }
      });
      // console.log("returnedExiles", returnedExiles);
      setCurrentExiles(updatedExiles);
      setLatestExiles(newExiles);
      setReturnedExiles(returnedExiles);
    },
    [currentExiles]
  );

  const start_timer = useCallback(() => {
    // console.log("starting timer.");

    let bellAudio = new Audio(require("../sounds/bell.mp3"));
    clearInterval(timer);
    setTimer(
      setInterval(() => {
        setTime((time) => {
          if (time === 0) {
            clearInterval(timer);
            return 0;
          } else if (time === 16) {
            bellAudio.play();
            return time - 1;
          } else return time - 1;
        });
      }, 1000)
    );
  }, [timer]);

  const addHistory = useCallback(() => {
    let newHistory = {};

    for (let i = 0; i < usernames.length; i++) {
      let currentUsername = usernames[i];
      // 0:DEAD 1:EXILED 2:INJURED 3:STAMPS
      newHistory[currentUsername] = [
        currentDead.indexOf(currentUsername) > -1,
        currentExiles.indexOf(currentUsername) > -1,
        currentInjured.indexOf(currentUsername) > -1,

        phase === "solar"
          ? [
              {
                name:
                  currentExiles.indexOf(currentUsername) === -1
                    ? "voteOut"
                    : "voteIn",
                active:
                  votedPlayers.indexOf(currentUsername) === -1 ? false : true,
              },
            ]
          : phase === "lunar"
          ? [
              {
                name: role["action"],
                active:
                  targetedPlayers.indexOf(currentUsername) === -1
                    ? false
                    : true,
              },
              // role["team"] === 0
              //   ? {
              //       name: "assassinate",
              //       active:
              //         assassinatedPlayers.indexOf(currentUsername) === -1
              //           ? false
              //           : true,
              //     }
              //   : null,
            ]
          : null,
        ,
      ];
    }


    let oldHistory = [...history];
    oldHistory.push(newHistory);
    setHistory(oldHistory);
  }, [
    history,
    targetedPlayers,
    votedPlayers,
    currentDead,
    currentExiles,
    phase,
    usernames,
    currentInjured,
    role,
    // assassinatedPlayers,
  ]);

  const changePhase = useCallback(
    (newPhase) => {
      if (newPhase === "solar") {
        action();
      } else if (newPhase === "lunar" && phase !== "start") {
        vote();
        setMoon(moon === 7 ? 0 : moon + 1);
      }

      setSelectedPlayer(null);
      setLockedIn(false);
      setLockedInUsernames([]);
      addHistory();

      if (phase === "start") {
        setPhase(newPhase);
        setTime(timeLimits[0]);
        start_timer();
      } else {
        setViewedPhase(phaseCount + 1);
        setPhaseCount(phaseCount + 1);

        setPhase(newPhase);
        setTime(newPhase === "lunar" ? timeLimits[0] : timeLimits[1]);
        start_timer();
        setLatestDead([]);
        setLatestExiles([]);
        setLatestInfo([]);
        setReturnedExiles([]);
        setTargetedPlayers([null]);
        setVotedPlayers([]);
        // setAssassinatedPlayers([null]);
        setRoleGuess(null);
        clickPlayer();
      }
    },
    [
      vote,
      action,
      start_timer,
      timeLimits,
      moon,
      phase,
      phaseCount,
      addHistory,
      clickPlayer,
    ]
  );


  const gameOver = useCallback((winner, finalUsers) => {
    setFinalUsers(finalUsers);
    setWinner(winner);
    setHideScreen(false);
  }, []);

  // useEffect(() => {
  //   console.log("cooldown", cooldown);
  // console.log(role["team"] === 0);
  // console.log(role["team"]);
  // usernames.map((currentUsername) => {
  //   console.log(currentUsername);
  //   console.log(visibleTeammates.indexOf(currentUsername) > -1);
  //   console.log(
  //     role["team"] === 0 && visibleTeammates.indexOf(currentUsername) > -1
  //   );
  // });
  // console.log(role["action"]);
  // }, [cooldown]);

  useEffect(() => {
    socket.on("vote_now", vote);
    socket.on("info", getInfo);
    socket.on("learn", learn);
    socket.on("exiles", getExiles);
    socket.on("phase", changePhase);
    socket.on("game_over", gameOver);
    socket.on("locked_in", getLockedInUsernames);

    return () => {
      socket.off("vote_now", vote);
      socket.off("info", getInfo);
      socket.off("learn", learn);
      socket.off("exiles", getExiles);
      socket.off("phase", changePhase);
      socket.off("game_over", gameOver);
      socket.off("locked_in", getLockedInUsernames);
    };
  }, [
    vote,
    getInfo,
    changePhase,
    gameOver,
    getExiles,
    learn,
    getLockedInUsernames,
  ]);

  const targetPlayer = useCallback(
    (username, roleGuess=null) => {
      let index = targetedPlayers.indexOf(username);
      if (index !== -1) {
        // if username in the array
        let newTargets = [
          ...targetedPlayers.slice(0, index),
          ...targetedPlayers.slice(index + 1),
        ];
        setTargetedPlayers(newTargets.length === 0 ? [null] : newTargets);
        console.log("Untargeted ", username);
      } else {
        // if username not in the array
        if (username === null) {
          setTargetedPlayers([null]);
        } else {

          setRoleGuess(roleGuess);

          let newTargets = [...targetedPlayers];
          newTargets.push(username);
          if (newTargets.length > role["targets"]) {
            newTargets.shift();
          }
          if (newTargets.length > 1 && newTargets.indexOf(null) > -1) {
            let nullIndex = newTargets.indexOf(null);
            newTargets.splice(nullIndex, 1);
          }
          setTargetedPlayers(newTargets);
          console.log("Targeted ", username);
        }
      }

      setSelectedPlayer(null);
    },
    // (targetedUsername) => {
    //   console.log("Instant Action");
    //   socket.emit("instant_action", { room, targetedUsername, username, role });
    // if (targetedPlayers.indexOf(null) === -1) {
    //   setCooldown(role["cooldown"]);
    //   console.log("tp", targetedPlayers);
    //   console.log(targetedPlayers.indexOf(null));
    // } else if (cooldown > 0) {
    //   setCooldown(cooldown - 1);
    // }
    // },
    [targetedPlayers, role]
  );

  const votePlayer = useCallback(
    (username) => {
      let index = votedPlayers.indexOf(username);
      if (index !== -1) {
        // if username in the array
        setVotedPlayers([
          ...votedPlayers.slice(0, index),
          ...votedPlayers.slice(index + 1),
        ]);
        console.log("Unvoted ", username);
      } else {
        // if username not in the array
        setVotedPlayers([username]);
        console.log("Voted ", username);
      }

      setSelectedPlayer(null);
    },
    [votedPlayers]
  );

  // const assassinatePlayer = useCallback(
  //   (username, newRoleGuess) => {
  //     let index = assassinatedPlayers.indexOf(username);
  //     if (index !== -1) {
  //       // if username in the array
  //       if (newRoleGuess !== roleGuess) {
  //         setRoleGuess(newRoleGuess);
  //         console.log("Guess changed from ", roleGuess, " to ", newRoleGuess);
  //       } else {
  //         setAssassinatedPlayers([
  //           ...assassinatedPlayers.slice(0, index),
  //           ...assassinatedPlayers.slice(index + 1),
  //         ]);
  //         setRoleGuess(null);
  //         console.log("Unguessing ", username);
  //       }
  //     } else {
  //       // if username not in the array
  //       setAssassinatedPlayers([username]);
  //       setRoleGuess(newRoleGuess);
  //       console.log("Guessing ", username, " as ", newRoleGuess);
  //     }

  //     setSelectedPlayer(null);
  //   },
  //   [assassinatedPlayers, roleGuess]
  // );

  return (
    <div
      className={` flex flex-col items-center justify-start  w-screen h-full   select-none`}
    >
      <div
        className={`w-full h-full absolute bg-cover -z-10 ${
          viewedPhase % 2 === 0 ? "bg-day bg-center" : "bg-night bg-top"
        } ${viewedPhase !== phaseCount ? "grayscale" : ""}`}
      ></div>
      {/* UPPER */}

      <div
        className={`justify-start items-center flex-col h-[85%] w-full relative portrait:flex landscape:hidden`}
      >
        {/* SELECTED PLAYER */}
        <div
          className={`${
            selectedPlayer
              ? "w-full h-full  flex flex-col justify-center items-center"
              : "hidden"
          }    absolute z-10`}
        >
          <div
            className={`w-full h-full bg-black absolute backdrop-blur bg-black/40 `}
            onClick={() => clickPlayer()}
            // onClick={() => clickPlayer()}
          ></div>

          <Player
            customization={selectedPlayer ? users.find((user) => user["username"] === selectedPlayer)["customization"] : null}
            username={selectedPlayer}
            stamps={[
              // {
              //   name: "assassinate",
              //   active: assassinatedPlayers.indexOf(selectedPlayer) !== -1,
              // },
              phase === "lunar"
                ? {
                    name: role["action"],
                    active: targetedPlayers.indexOf(selectedPlayer) !== -1,
                  }
                : phase === "solar"
                ? currentExiles.indexOf(selectedPlayer) === -1
                  ? {
                      name: "voteOut",
                      active: votedPlayers.indexOf(selectedPlayer) !== -1,
                    }
                  : {
                      name: "voteIn",
                      active: votedPlayers.indexOf(selectedPlayer) !== -1,
                    }
                : null,
            ]}
            damage={
              currentDead.indexOf(selectedPlayer) > -1
                ? 2
                : currentInjured.indexOf(selectedPlayer) > -1
                ? 1
                : 0
            }
            exiled={currentExiles.indexOf(selectedPlayer) > -1}
            selectFunction={() => clickPlayer(selectedPlayer)}
            highlight={
              selectedPlayer === username
                ? "yellow"
                : role["team"] === 0 &&
                  visibleTeammates.indexOf(selectedPlayer) > -1
                ? "red"
                : role["team"] === 1 &&
                  visibleTeammates.indexOf(selectedPlayer) > -1
                ? "blue"
                : null
            }
            state={"game"}
            lockedIn={lockedIn}
            lockedInVisual={lockedInUsernames.indexOf(selectedPlayer) > -1}
          ></Player>

          <div
            className={`w-full h-2/3  flex flex-col justify-start items-center pt-10 gap-1 overflow-y-scroll `}
          >
            <ActionButton
              text={
                phase === "lunar"
                  ? role["action"]
                  : phase === "solar"
                  ? currentExiles.indexOf(selectedPlayer) > -1
                    ? "Vote In"
                    : "Vote Out"
                  : null
              }
              action={
                phase === "lunar"
                  ? role["action"]
                  : phase === "solar"
                  ? currentExiles.indexOf(selectedPlayer) > -1
                    ? "voteIn"
                    : "voteOut"
                  : null
              }
              clickFunction={
                phase === "lunar"
                  ? role["action"] === "assassinate" ? () => setActionButtonPage(1) : () => targetPlayer(selectedPlayer)
                  : phase === "solar"
                  ? () => votePlayer(selectedPlayer)
                  : null
              }
              active={
                phase === "lunar"
                  ? winner === null &&
                    selectedPlayer !== username &&
                    currentDead.indexOf(username) === -1 &&
                    currentExiles.indexOf(username) === -1 &&
                    (role["action"] === "resurrect"
                      ? currentDead.indexOf(selectedPlayer) > -1
                      : currentDead.indexOf(selectedPlayer) === -1) &&
                    cooldown === 0 &&
                    (role["action"] === "return"
                      ? currentExiles.indexOf(selectedPlayer) > -1
                      : role["action"] === "exile"
                      ? currentExiles.indexOf(selectedPlayer) === -1
                      : role["action"] === "heal"
                      ? currentInjured.indexOf(selectedPlayer) > -1
                      : true)
                  : phase === "solar"
                  ? winner === null &&
                    selectedPlayer !== username &&
                    (currentDead.indexOf(username) === -1 ||
                      (currentDead.indexOf(username) > -1 && !ghostVoteUsed)) &&
                    currentExiles.indexOf(username) === -1 &&
                    currentDead.indexOf(selectedPlayer) === -1
                  : false
              }
              visible={actionButtonPage === 0}
            ></ActionButton>

            {/* {role["team"] === 0 &&
            visibleTeammates.indexOf(selectedPlayer) === -1 &&
            selectedPlayer !== username &&
            phase === "lunar" ? (
              <ActionButton
                text={"Assassinate"}
                action={"assassinate"}
                clickFunction={() => setActionButtonPage(1)}
                active={
                  winner === null &&
                  selectedPlayer !== username &&
                  currentDead.indexOf(username) === -1 &&
                  currentDead.indexOf(selectedPlayer) === -1
                }
                visible={actionButtonPage === 0}
              ></ActionButton>
            ) : null} */}

            {role["team"] === 0 &&
            visibleTeammates.indexOf(selectedPlayer) === -1 &&
            selectedPlayer !== username
              ? Object.values(roles).map((role) => {
                  return role["team"] !== 0 ? (
                    <ActionButton
                      text={role["name"]}
                      action={"assassinate"}
                      clickFunction={() =>
                        targetPlayer(selectedPlayer, role["name"])
                      }
                      visible={actionButtonPage === 1}
                    ></ActionButton>
                  ) : null;
                })
              : null}
          </div>
        </div>

        {/* <div
          className={`w-full h-full bg-black/30 absolute flex flex-col items-center justify-center z-40 pointer-events-none`}
        ></div> */}

        {/* START PHASE */}
        <div
          className={`w-full h-full flex flex-col justify-center gap-5 items-center ${
            phase === "start" ? "flex" : "hidden"
          }`}
        >
          <div className={` justify-center items-center `}>
            <Role role={role} highlight={true}></Role>
          </div>

          <Button clickFunction={() => readyUp()}>
            {readiedUp ? "Unready" : "Ready"}
          </Button>
        </div>

        {/* PLAYER DISPLAY */}

        <div
          className={`${
            phase !== "start" ? "flex" : "hidden"
          } flex-row  overflow-y-scroll w-full h-full justify-around items-center gap-y-5  py-5 flex-wrap  `}
        >
          {viewedPhase === phaseCount
            ? users.map((user, index) => {
                return (
                  <Player
                    customization={user["customization"]}
                    username={user["username"]}
                    stamps={[
                      // {
                      //   name: "assassinate",
                      //   active:
                      //     assassinatedPlayers.indexOf(user["username"]) !== -1,
                      // },
                      phase === "lunar"
                        ? {
                            name: role["action"],
                            active:
                              targetedPlayers.indexOf(user["username"]) !== -1,
                          }
                        : phase === "solar"
                        ? currentExiles.indexOf(user["username"]) === -1
                          ? {
                              name: "voteOut",
                              active:
                                votedPlayers.indexOf(user["username"]) !== -1,
                            }
                          : {
                              name: "voteIn",
                              active:
                                votedPlayers.indexOf(user["username"]) !== -1,
                            }
                        : null,
                    ]}
                    damage={
                      currentDead.indexOf(user["username"]) > -1
                        ? 2
                        : currentInjured.indexOf(user["username"]) > -1
                        ? 1
                        : 0
                    }
                    exiled={currentExiles.indexOf(user["username"]) > -1}
                    // selectable={
                    //   phase === "lunar"
                    //     ? winner === null &&
                    //       currentUsername !== username &&
                    //       currentDead.indexOf(username) === -1 &&
                    //       currentExiles.indexOf(username) === -1 &&
                    //       (role["action"] === "resurrect"
                    //         ? currentDead.indexOf(currentUsername) > -1
                    //         : currentDead.indexOf(currentUsername) === -1) &&
                    //       cooldown === 0 &&
                    //       (role["action"] === "return"
                    //         ? currentExiles.indexOf(currentUsername) > -1
                    //         : role["action"] === "exile"
                    //         ? currentExiles.indexOf(currentUsername) === -1
                    //         : role["action"] === "heal"
                    //         ? currentInjured.indexOf(currentUsername) > -1
                    //         : true)
                    //     : phase === "solar"
                    //     ? winner === null &&
                    //       currentUsername !== username &&
                    //       (currentDead.indexOf(username) === -1 ||
                    //         (currentDead.indexOf(username) > -1 &&
                    //           !ghostVoteUsed)) &&
                    //       currentExiles.indexOf(username) === -1 &&
                    //       currentDead.indexOf(currentUsername) === -1
                    //     : false
                    // }
                    // selectAction={
                    //   phase === "lunar"
                    //     ? role["action"]
                    //     : phase === "solar"
                    //     ? currentExiles.indexOf(currentUsername) > -1
                    //       ? "voteIn"
                    //       : "voteOut"
                    //     : null
                    // }
                    // selected={currentUsername === selectedPlayer}
                    // selected={
                    //   phase === "lunar"
                    //     ? targetedPlayers.indexOf(currentUsername) > -1
                    //     : phase === "solar"
                    //     ? votedPlayers.indexOf(currentUsername) > -1
                    //     : null
                    // }
                    selectFunction={winner === null ? () => clickPlayer(user["username"]) : null}
                    // options={[
                    //   <ActionButton
                    //     text={role["action"]}
                    //     clickFunction={() => targetPlayer(currentUsername)}
                    //     action={role["action"]}
                    //     active={
                    //       winner === null &&
                    //       currentUsername !== username &&
                    //       currentDead.indexOf(username) === -1 &&
                    //       currentExiles.indexOf(username) === -1 &&
                    //       (role["action"] === "resurrect"
                    //         ? currentDead.indexOf(currentUsername) > -1
                    //         : currentDead.indexOf(currentUsername) === -1) &&
                    //       cooldown === 0 &&
                    //       (role["action"] === "return"
                    //         ? currentExiles.indexOf(currentUsername) > -1
                    //         : role["action"] === "exile"
                    //         ? currentExiles.indexOf(currentUsername) === -1
                    //         : role["action"] === "heal"
                    //         ? currentInjured.indexOf(currentUsername) > -1
                    //         : true)
                    //     }
                    //   ></ActionButton>,
                    //   role["team"] === 0 ? (
                    //     <ActionButton
                    //       text={"Assassinate"}
                    //       action={"assassinate"}
                    //       secondaryButtons={[
                    //         roles
                    //           ? Object.values(roles).map(
                    //               (currentRole, index) => {
                    //                 return (
                    //                   <ActionButton
                    //                     text={currentRole["name"]}
                    //                     action={"assassinate"}
                    //                   ></ActionButton>
                    //                 );
                    //               }
                    //             )
                    //           : null,
                    //       ]}
                    //     ></ActionButton>
                    //   ) : null,
                    // ]}
                    // selectFunction={
                    //   phase === "lunar"
                    //     ? () => targetPlayer(currentUsername)
                    //     : phase === "solar"
                    //     ? () => votePlayer(currentUsername)
                    //     : null
                    // }
                    highlight={
                      user["username"] === username
                        ? "yellow"
                        : role["team"] === 0 &&
                          visibleTeammates.indexOf(user["username"]) > -1
                        ? "red"
                        : role["team"] === 1 &&
                          visibleTeammates.indexOf(user["username"]) > -1
                        ? "blue"
                        : null
                    }
                    state={"game"}
                    role={
                      winner === null
                        ? null
                        : finalUsers.find(
                            (finalUser) =>
                              finalUser.username === user["username"]
                          )["role"]
                    }
                    winner={
                      winner === null
                        ? null
                        : finalUsers.find(
                            (finalUser) =>
                              finalUser.username === user["username"]
                          )["role"]["team"] === winner
                    }
                    lockedIn={lockedIn}
                    lockedInVisual={
                      lockedInUsernames.indexOf(user["username"]) > -1
                    }
                    key={index}
                  ></Player>
                );
              })
            : null}

          {viewedPhase !== phaseCount && entrancePhaseCount === 1
            ? users.map((user, index) => {
                return (
                  <Player
                    username={user["username"]}
                    damage={
                      history[viewedPhase][user["username"]][0]
                        ? 2
                        : history[viewedPhase][user["username"]][2]
                        ? 1
                        : 0
                    }
                    exiled={history[viewedPhase][user["username"]][1]}
                    highlight={
                      user["username"] === username
                        ? "yellow"
                        : role["team"] === 0 &&
                          visibleTeammates.indexOf(user["username"]) > -1
                        ? "red"
                        : role["team"] === 1 &&
                          visibleTeammates.indexOf(user["username"]) > -1
                        ? "blue"
                        : null
                    }
                    stamps={history[viewedPhase][user["username"]][3]}
                    state={"history"}
                    key={index}
                    customization={user["customization"]}
                  ></Player>
                );
              })
            : null}
        </div>
      </div>

      {/* LOWER */}

      <div
        className={`w-full h-[15%] border-b-4 border-black/10 bg-black/20 backdrop-blur   items-center justify-between  z-30 ${
          phase !== "start" ? "portrait:flex landscape:hidden" : "hidden"
        }`}
      >
        <div
          className={`flex flex-col justify-center items-center w-[40%] h-full border-r-4 border-black/20`}
        >
          <div
            className={`text-white  w-full h-1/2 flex text-2xl justify-center items-center text-balance`}
            onClick={() => setViewedPhase(phaseCount)}
          >
            {viewedPhase % 2 === 0 ? "Day" : "Night"}{" "}
            {viewedPhase % 2 === 0
              ? Math.floor(viewedPhase / 2)
              : Math.floor(viewedPhase / 2) + 1}
          </div>

          <div
            className={` w-full  h-1/2 items-center justify-center ${
              entrancePhaseCount === 1 ? "flex" : "hidden"
            }`}
          >
            <div className={`h-full w-1/2 flex   justify-center items-center`}>
              <div
                onClick={() => setViewedPhase(viewedPhase - 1)}
                className={`w-full h-full     justify-center items-center text-white text-4xl ${
                  viewedPhase === 1 ? "hidden" : "flex"
                }`}
              >
                <FontAwesomeIcon icon={faCaretLeft}></FontAwesomeIcon>
              </div>
            </div>
            <div className={`h-full w-1/2 flex justify-center items-center `}>
              <div
                onClick={() => setViewedPhase(viewedPhase + 1)}
                className={`w-full h-full  justify-center items-center text-white text-4xl ${
                  viewedPhase === phaseCount ? "hidden" : "flex"
                }`}
              >
                <FontAwesomeIcon icon={faCaretRight}></FontAwesomeIcon>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`w-[50px] aspect-square border-4 border-neutral-300 text-neutral-300 text-2xl ${
            winner === null ? "flex" : "hidden"
          } justify-center items-center  rounded-full`}
          onClick={() => setHideScreen(!hideScreen)}
        >
          <FontAwesomeIcon
            icon={hideScreen ? faEye : faEyeSlash}
          ></FontAwesomeIcon>
        </div>

        <div
          className={`w-[50px] aspect-square border-4 border-neutral-300 text-neutral-300 text-2xl ${
            winner === null && phase !== "start" ? "flex" : "hidden"
          } justify-center items-center  rounded-full`}
          onClick={() => lockIn()}
        >
          <FontAwesomeIcon
            icon={lockedIn ? faLock : faLockOpen}
          ></FontAwesomeIcon>
        </div>

        <div
          className={`h-full w-[60%] text-3xl ${
            winner !== null ? "flex" : "hidden"
          } items-center justify-center ${
            winner === role["team"] ? "text-yellow-400" : "text-white"
          }`}
        >
          {winner === role["team"] ? "Victory!" : "Defeat."}
        </div>

        <div
          className={`text-2xl w-[25%] h-full ${
            winner !== null && (phase === "lunar" || phase === "solar")
              ? "hidden"
              : phase !== "start"
              ? "flex"
              : "hidden"
          } justify-center items-center text-center border-box 
          ${time <= 15 ? "text-red-500" : "text-white"}  
          border-l-4 border-black/20`}
        >
          {`${Math.floor(time / 60)}`.padStart(2, 0)}:
          {`${time % 60}`.padStart(2, 0)}
        </div>
      </div>

      <div
        className={`portrait:hidden landscape:flex w-full h-full justify-center items-center `}
      >
        <div className={`flex w-2/3 h-full justify-center items-center gap-1`}>
        <Selector
            direction={"left"}
            clickFunction={() =>
              setViewedRole(
                viewedRole === 0
                  ? Object.keys(roles).length - 1
                  : viewedRole - 1
              )
            }
          ></Selector>

          {roles &&
            Object.values(roles).map((currentRole, index) => {
              return (
                <Role
                  role={currentRole}
                  visible={viewedRole === index}
                  highlight={currentRole["name"] === role["name"]}
                  key={index}
                ></Role>
              );
            })}

          <Selector
            direction={"right"}
            clickFunction={() =>
              setViewedRole(
                viewedRole === Object.keys(roles).length - 1
                  ? 0
                  : viewedRole + 1
              )
            }
          ></Selector>
        </div>
        {host === username ? (
          <Button clickFunction={returnToLobby}>End</Button>
        ) : null}
      </div>

      <div
        className={`absolute h-full bottom-0 w-full z-20 bg-transition bg-center bg-cover ${
          hideScreen ? "flex justify-center items-center" : "hidden"
        }`}
      ></div>

      <div
        className={`absolute h-full w-full bottom-0 bg-transition bg-center bg-cover ${
          phase === "reconnect"
            ? "portrait:flex landscape:hidden justify-center items-center flex-col"
            : "hidden"
        } z-40 gap-1`}
      >
        <Textplate>You will be returned to the game next phase.</Textplate>
        <Role role={role}></Role>
      </div>
    </div>
  );
}
