import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCross,
  faCrown,
  faDroplet,
  faFlask,
  faHandBackFist,
  faHandHoldingHeart,
  faHandHoldingMedical,
  faHandPointLeft,
  faHeart,
  faHeartBroken,
  faLock,
  faPeopleGroup,
  faPersonHiking,
  faShield,
  faSkull,
  faSkullCrossbones,
  faStar,
  faThumbsDown,
  faThumbsUp,
  faWandSparkles,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

import { useCallback, useEffect, useState } from "react";

export default function Player(props) {
  const {
    username,
    highlight,
    selectFunction,
    damage = 0,
    exiled = false,
    state = "lobby",
    role = null,
    winner = null,
    lockedInVisual = false,
    stamps,
    customization,
  } = props;

  useEffect(() => {
    console.log(customization);
  }, [customization]);
  const actionSymbols = {
    voteOut: faThumbsDown,
    voteIn: faThumbsUp,
    attack: faHandBackFist,
    protect: faShield,
    poison: faFlask,
    exile: faHandPointLeft,
    return: faHandHoldingHeart,
    heal: faHandHoldingMedical,
    execute: faSkullCrossbones,
    resurrect: faWandSparkles,
    host: faStar,
    assassinate: faSkullCrossbones,
  };

  const statusColours = {
    green: "bg-green-900 text-green-600 border-green-600  ",
    neutral: "bg-neutral-800 text-neutral-500 border-neutral-500",
    red: "bg-red-900 text-red-600 ",
    orange: "bg-orange-900 text-orange-600 ",
  };

  const colours = {
    green: "bg-green-900 text-green-600 border-green-600  ",
    neutral: "bg-neutral-800 text-neutral-500 border-neutral-500",
    red: "bg-red-900 text-red-600 ",
    orange: "bg-orange-900 text-orange-600 ",
  };

  const primaryColours = {
    red: "bg-gradient-to-t from-rose-900 to-red-700",
    orange: "bg-gradient-to-t from-orange-800 to-orange-600",
    yellow: "bg-gradient-to-t from-yellow-700 to-yellow-500",
    green: "bg-gradient-to-t from-lime-700 to-lime-500",
    blue: "bg-gradient-to-t from-sky-700 to-sky-500",
    indigo: "bg-gradient-to-t from-indigo-700 to-indigo-500",
    violet: "bg-gradient-to-t from-fuchsia-900 to-fuchsia-700",
  };

  const secondaryColours = {
    red: "bg-gradient-to-b from-rose-300 to-red-200",
    orange: "bg-gradient-to-b from-orange-300 to-orange-200",
    yellow: "bg-gradient-to-b from-yellow-300 to-yellow-200",
    green: "bg-gradient-to-b from-lime-300 to-lime-200",
    blue: "bg-gradient-to-b from-sky-300 to-sky-200",
    indigo: "bg-gradient-to-b from-indigo-300 to-indigo-200",
    violet: "bg-gradient-to-b from-fuchsia-300 to-fuchsia-200",
  };

  return (
    <div
      className={`  text-2xl 
        relative w-1/2
      }  max-w-1/2 h-[100px]
       flex-basis-1/2 bg-cover bg-center  border-neutral-900 select-none rounded-xl  text-center select-none flex items-center justify-center box-border ${
         highlight === "yellow"
           ? "text-yellow-200"
           : highlight === "red"
           ? "text-red-300"
           : highlight === "blue"
           ? "text-blue-300"
           : "text-neutral-300"
       } `}
      onClick={selectFunction}
    >
      <div
        className={`absolute  flex  
        w-[80%] text-xl h-[90%] 
        transition-all flex-basis-1/2 bg-cover bg-center   border-40
        select-none rounded-xl  text-center select-none text-center flex items-center justify-center box-border ${
          highlight === "yellow"
            ? "text-yellow-200"
            : highlight === "red"
            ? "text-red-600"
            : highlight === "blue"
            ? "text-blue-500"
            : "text-neutral-300"
        } `}
      >
        {/* HEALTH DISPLAY */}
        <div
          className={` w-min h-[135%] absolute -left-5 bg-gradient-to-r from-yellow-700 to-yellow-800 border-black rounded-full ${
            state === "game" || state === "history" ? "flex" : "hidden"
          } flex-col shrink-0 justify-around items-center p-1 gap-1`}
        >
          <div
            className={`w-min h-[30%] aspect-square rounded-full flex justify-center items-center ${
              damage >= 2
                ? statusColours["red"] + " shadow-centralRed"
                : statusColours["neutral"]
            } transition-all`}
          >
            <FontAwesomeIcon icon={faSkull}></FontAwesomeIcon>
          </div>

          <div
            className={`w-min h-[30%] aspect-square rounded-full flex justify-center items-center ${
              damage === 1
                ? statusColours["orange"] + " shadow-centralOrange"
                : statusColours["neutral"]
            } transition-all`}
          >
            <FontAwesomeIcon icon={faHeartBroken}></FontAwesomeIcon>
          </div>

          <div
            className={`w-min h-[30%] aspect-square rounded-full flex justify-center items-center ${
              damage === 0
                ? statusColours["green"] + " shadow-centralGreen"
                : statusColours["neutral"]
            } transition-all`}
          >
            <FontAwesomeIcon icon={faHeart}></FontAwesomeIcon>
          </div>
        </div>

        {/* CARD */}
        <div
          className={`w-full   h-full bg-cover bg-center rounded-tr rounded-br flex flex-col justify-start items-center `}
        >
          <div
            className={`w-full h-[40%] ${
              primaryColours[customization && customization["primaryColour"]]
            }  flex justify-center items-center rounded-t-xl `} // bg-gradient-to-t from-neutral-950 to-neutral-900
          >
            <div
              className={`pr-3 text-yellow-400 ${
                winner ? "flex justify-center items-center" : "hidden"
              }`}
            >
              {winner ? (
                <FontAwesomeIcon icon={faCrown}></FontAwesomeIcon>
              ) : null}
            </div>

            <div
              className={`pr-3 text-neutral-400 ${
                lockedInVisual
                  ? "flex justify-center items-center gap-3"
                  : "hidden"
              }`}
            >
              {lockedInVisual ? (
                <FontAwesomeIcon icon={faLock}></FontAwesomeIcon>
              ) : null}
            </div>

            <div
              className={`
                ${
                role && highlight !== "yellow"
                  ? role["team"] === 0
                    ? "text-red-600"
                    : "text-blue-500"
                  : null
              }`}
            >
              {username ? username : "Nobody"}
            </div>

            <div
              className={`w-[50px] aspect-square absolute -right-5 rounded-full ${
                exiled ? "flex items-center justify-center" : "hidden"
              } bg-red-800 text-black text-3xl`}
            >
              <FontAwesomeIcon icon={faPersonHiking}></FontAwesomeIcon>
            </div>
          </div>

          {/* STAMP HOLDER */}

          <div
            className={`w-[100%] h-[60%]
               p-1 justify-center items-center gap-1 
               flex rounded-b-xl text-md 

               ${
                 secondaryColours[
                   customization && customization["secondaryColour"]
                 ]
               }
             
             ${
               role
                 ? role["team"] === 0
                   ? "text-red-600"
                   : "text-blue-500"
                 : null
             }
             
             `}
          >
            {stamps
              ? stamps.map((dict, index) => {
                  return dict && dict.active ? (
                    <div
                      className={`w-min h-full aspect-square bg-black rounded-full flex items-center justify-center ${colours["green"]}`}
                      key={index}
                    >
                      <FontAwesomeIcon
                        icon={actionSymbols[dict.name]}
                      ></FontAwesomeIcon>
                    </div>
                  ) : null;
                })
              : null}

            {role ? role["name"] : null}
          </div>
        </div>
      </div>
    </div>
  );
}
